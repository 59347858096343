
  import { Component } from 'vue-property-decorator'
  import { GPdf } from '@/components/dataTables/PDF/GPdf'
  import jsPDF from 'jspdf'
  import { LinkedCellOptions } from '@/components/dataTables/cell/index'
  import { plainToInstance } from 'class-transformer'
  import { SaleOrder } from '@/entities/sales'
  import dayjs from 'dayjs'
  import { Process } from '@/entities/settings'
  import { fixPrice, fixThousands } from '@/utils/general'

@Component
  export default class warrantyPDF extends GPdf {
  declare options: LinkedCellOptions
  loading = false;
  imgUrl = '@/assets/companyLogo/logo.jpg'; // URL de la imagen
  carPhoto: string = ''
  saleOrder = plainToInstance(SaleOrder, {})
  disabledPdf = true
  process = plainToInstance(Process, {})

  async mounted () {
    const { item: { id } } = this
    this.saleOrder = await this.fetchData({
      query: { name: 'fetch', model: 'SaleOrder', params: { id } },
      force: true,
    })

    this.process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'sale_order' } },
      force: true,
    }))[0]

    const products = await this.fetchData({
      query: { name: 'find', model: 'SaleProduct' },
      filter: { id_sale_order: { _eq: this.saleOrder.id } },
      force: true,
    })
    const warranty = products?.find(product => product.name === 'warranty')
    this.disabledPdf = !this.process.config.warranty.enabled || !warranty?.id || this.saleOrder.closingReason?.description === 'Anulada'
  }

  async generatePDF () {
    this.loading = true

    this.saleOrder = await this.fetchData({
      query: { name: 'fetch', model: 'SaleOrder', params: { id: this.item.id } },
      force: true,
    })

    this.saleOrder.deal = await this.fetchData({
      query: { name: 'fetch', model: 'Deal', params: { id: this.saleOrder.deal.id } },
      force: true,
    })
    this.saleOrder.deal.payments = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: { id_deal: { _eq: this.saleOrder.deal.id } },
      force: true,
    })
    for (const payment of this.saleOrder.deal.payments) {
      const index = this.saleOrder.deal.payments.indexOf(payment)
      if (payment.financialAccount) {
        this.saleOrder.deal.payments[index].financialAccount = (await this.fetchData({
          query: { name: 'find', model: 'FinancialAccount' },
          filter: { id: { _eq: payment.financialAccount.id } },
          force: true,
        }))[0]
      }
    }

    const fileParameter = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: {
        _and: [
          { process: { table_name: { _eq: 'stock' } } },
          { file_type: { name: { _eq: 'photo' } } },
          { name: { _eq: 'right_front' } },
        ],
      },
    })
    const fileProcess = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: this.saleOrder.deal.stock.id } },
          { id_file_parameter: { _eq: fileParameter[0].id } },
        ],
      },
      force: true,
    })

    this.carPhoto = await this.loadImage(fileProcess[0]?.file?.largeUrl)

    const JsPDF = jsPDF
    const doc = new JsPDF('p', 'mm', 'a2')

    await this.getDocData()

    await doc.html(this.$refs.pdfContent)
    doc.save(`VENTA ${this.saleOrder.deal.lead.client.fullName} ${this.saleOrder.deal.lead.client.uid}.pdf`)
    this.loading = false
  }

  async getDocData () {
    await this.getCar()
    await this.getPrices()
    await this.getSystem()
    await this.getTemplate()
    this.getExecutive()
    this.getMetadata()
    this.getClient()
    this.getWarranties()
  }

  getClient () {
    const { client } = this.saleOrder.deal.lead
    const { fullName, uid } = client

    this.$set(this.docData, 'client', {
      fullName,
      uid,
    })
  }

  getWarranties () {
    const { saleOrder } = this
    const warranties = Object.entries(this.process.config.warranty.expiration.product_warranty).map(item => item[1])
    const mechanicalWarranty = warranties.filter(warranty => (warranty as any).text === 'Garantía mecánica')[0]
    const acWarranty = warranties.filter(warranty => (warranty as any).text === 'Aire acondicionado')[0]
    this.$set(this.docData, 'variables', {
      mechanical_warranty_date: (saleOrder.deliveryDate || dayjs(saleOrder.createdAt)).add((mechanicalWarranty as any)?.duration?.months, 'month').format('DD/MM/YYYY'),
      ac_warranty_date: (saleOrder.deliveryDate || dayjs(saleOrder.createdAt)).add((acWarranty as any)?.duration?.months, 'month').format('DD/MM/YYYY'),
    })
      // (saleOrder.deliveryDate || dayjs(saleOrder.createdAt)).add(warranty.duration.months, 'month').format('DD/MM/YYYY')
  }

  async getTemplate () {
    const template = (await this.fetchData({
      query: { name: 'find', model: 'DocumentType' },
      filter: { name: { _eq: 'postsale_warranty' } },
    }))[0].template

    this.$set(this.docData, 'template', template)
  }

  getExecutive () {
    const { capitalizeFirstLetter } = this
    const executive = this.saleOrder?.deal?.lead?.executive?.person

    this.$set(this.docData, 'executive', executive ? {
      fullName: `${capitalizeFirstLetter(executive?.firstName.trim())} ${capitalizeFirstLetter(executive?.surname.trim())}` || 'No informado',
      phone: executive?.phoneWork || 'Teléfono no informado',
      email: executive?.email.work || 'Email no informado',
      uid: executive?.uid,
    } : undefined)
  }

  async getPrices () {
    const saleProducts = await this.fetchData({
      query: { name: 'find', model: 'SaleProduct' },
      filter: { id_sale_order: { _eq: this.saleOrder.id } },
      force: true,
    })

    const listPrice = this.saleOrder.saleOrderItems.filter(item => item.type.name === 'product')?.[0]?.amount || 0

    const givenDiscount = this.saleOrder.saleOrderItems.filter(item => item.type.name === 'discount')?.[0]?.amount || 0
    const postFinancingDiscount = this.saleOrder.saleOrderItems.filter(item => item.type.name === 'post_financing_discount')?.[0]?.amount || 0
    const totalDiscount = givenDiscount + postFinancingDiscount

    const salePrice = listPrice + totalDiscount
    const transferCost = this.saleOrder.saleOrderItems.filter(item => item.type.name === 'transfer')?.[0]?.amount || 0
    const warranty = saleProducts.filter(item => item.name === 'warranty')?.[0]
    const warrantyCost = warranty?.cost || 0
    const totalCost = listPrice + totalDiscount + transferCost + warrantyCost

    this.docData.prices = {
      list: listPrice ? fixPrice(listPrice) : '-',
      sale: salePrice ? fixPrice(salePrice) : '-',
      totalDiscount: totalDiscount ? fixPrice(Math.abs(totalDiscount)) : '-',
      total: totalCost ? fixPrice(totalCost) : '-',
      transfer: totalCost ? fixPrice(transferCost) : '-',
      services: fixPrice(transferCost + warrantyCost),
      warranty: warrantyCost ? fixPrice(warrantyCost) : '-',
    }

    this.docData.warrantyBroker = warranty?.broker
  }

  async getCar () {
    const { auto } = this.saleOrder.deal
    const stockViewDetail = (await this.fetchData({
      query: { name: 'find', model: 'StockViewDetails' },
      filter: { stock: { id: { _eq: this.saleOrder.deal.stock.id } } },
    }))[0]
    const autoAttributes = stockViewDetail.attributes
    const chassisNumberDealAttribute = autoAttributes.filter(attribute => attribute.component.slug === 'chassis_serial')[0]
    const engineNumberDealAttribute = autoAttributes.filter(attribute => attribute.component.slug === 'engine_serial')[0]
    const mileages = stockViewDetail.attributes
      .filter(detail => detail.component.slug === 'mileage')
      .sort((prev, next) => next.value - prev.value)
    const mileage = mileages[0]

    this.$set(this.docData, 'car', {
      plate: auto.registrationPlate,
      brand: auto.version.version.model.brand.name,
      model: `${auto.version.version.model.name}  ${auto.version.version.name}`,
      year: auto.version.year.id,
      mileage: mileage.value ? fixThousands(mileage.value) : 'NO INFORMADO',
      engineNumber: engineNumberDealAttribute?.value,
      chassisNumber: chassisNumberDealAttribute?.value,
    })
  }

  getMetadata () {
    const { saleOrder } = this
    const id = `${dayjs(saleOrder.createdAt).format('YYYY')}${'0'.repeat(9 - String(saleOrder.id).length)}${saleOrder.id}`
    const date = dayjs(saleOrder.deliveryDate || saleOrder.createdAt).format('DD /MM /YYYY')

    this.$set(this.docData, 'metadata', {
      id,
      date,
    })
  }

  // DEPRECATED
  addSubtitle (doc, x, y) {
    const documentId = `N°${dayjs(this.saleOrder.createdAt).format('YYYY')}${'0'.repeat(9 - String(this.saleOrder.id).length)}${this.saleOrder.id}`
    const hourText = `Hora: ${dayjs(this.saleOrder.deliveryDate || this.saleOrder.createdAt).format('HH:mm')}`
    const dateText = `Fecha: ${dayjs(this.saleOrder.deliveryDate || this.saleOrder.createdAt).format('DD/MM/YYYY')}`
    doc.text(documentId, x, y)
    doc.text(hourText, this.getCenterWidth(doc, hourText), y)
    doc.text(dateText, this.getAlignEnd(doc, dateText), y)
    y += 5
    y = this.separator(doc, y)
    return y
  }

  async addCarData (doc, x, y) {
    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'enablement' } },
      force: true,
    }))[0]
    const enablement = ((await this.fetchData({
      query: { name: 'find', model: 'Enablement' },
      filter: { stock: { id: { _eq: this.saleOrder.deal.stock.id } } },
      force: true,
    })).sort((prev, next) => prev.id - next.id))[0]
    const component = (await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: { slug: { _eq: 'mileage' } },
      force: true,
    }))[0]
    const mileage = (await this.fetchData({
      query: { name: 'find', model: 'AttributeView' },
      filter: {
        _and: [
          { id_process: { _eq: process.id } },
          { id_process_record: { _eq: enablement.id } },
          { id_component: { _eq: component.id } },
        ],
      },
    }))[0]
    y = this.addSectionTitle(doc, x, y, 'Datos del vehículo')
    if (this.carPhoto) {
      doc.addImage(this.carPhoto, 'JPEG', x, y - 3, 50, 35)
    }
    y = this.insertList(doc, x + (this.carPhoto ? 55 : 0), y, [
        'Patente',
        'Año',
        'Marca',
        'Modelo',
        'Kilometraje',
      ],
      [
        this.saleOrder.deal.auto.registrationPlate + '|bold',
        String(this.saleOrder.deal.auto.version.year.id),
        this.saleOrder.deal.auto.version.version.model.brand.name,
        this.saleOrder.deal.auto.version.version.model.name + ' ' + this.saleOrder.deal.auto.version.version.name + '|bold',
        `${fixThousands(mileage.value)} kms`,
      ])

    y = this.separator(doc, y)

    return y
  }

  async addClosingSection (doc: jsPDF, y: number) {
    const clientService = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })

    const executiveName = this.saleOrder.deal.lead.executive ? `${this.saleOrder.deal.lead.executive.person.firstName} ${this.saleOrder.deal.lead.executive.person.surname}` : 'No informado'
    this.addClosing(doc, [
        'Asesor comercial:',
        executiveName,
        `Tel: ${this.saleOrder.deal.lead.executive ? this.saleOrder.deal.lead.executive.person.phoneWork : 'No informado'}`,
        this.saleOrder.deal.lead.executive ? this.saleOrder.deal.lead.executive.person.email.work : 'Email no informado',
      ],
      [
        'Atención al cliente:',
        'Tel: ' + clientService.phoneWork,
      ],
    )
  }

  addConditions (doc, x, y) {
    y = this.addSectionTitle(doc, x, y, 'Condiciones')
    y = this.insertNumberedList(doc, x, y, this.process.config.warranty.conditions.list)
    y = this.separator(doc, y)
    return y
  }

  addCoverage (doc, x, y) {
    y = this.addSectionTitle(doc, x, y, 'Cobertura')
    y = this.addParagraph(doc, x, y, this.process.config.warranty.coverage.text)
    y = this.separator(doc, y)
    y = this.insertNumberedList(doc, x, y, this.process.config.warranty.coverage.list)
    y = this.separator(doc, y)
    return y
  }

  addConsiderations (doc, x, y) {
    if (y > doc.internal.pageSize.getHeight() - 80) {
      y = this.newPage(doc, y)
      this.addClosingSection(doc, y)
    }
    y = this.addSectionTitle(doc, x, y, 'Consideraciones')
    y = this.insertNumberedList(doc, x, y, this.process.config.warranty.considerations.list)
    y = this.separator(doc, y)
    return y
  }

  addExclusions (doc: jsPDF, x, y) {
    y = this.addSectionTitle(doc, x, y, 'Exclusiones')
    y = this.insertNumberedList(doc, x, y, this.process.config.warranty.exclusions.list.map(item => item.split('[nl]')[0]))
    this.process.config.warranty.exclusions.list.forEach(item => {
      const subList = item.split('[nl]')?.[1]
      if (subList) {
        y = this.insertList(doc, x + 5, y, subList.split(', '), [])
      }
    })
    y = this.separator(doc, y)
    return y
  }

  addExpiracy (doc, x, y) {
    if (y > doc.internal.pageSize.getHeight() - 120) {
      y = this.newPage(doc, y)
      this.addClosingSection(doc, y)
    }
    y = this.addSectionTitle(doc, x, y, 'Caducidad')

    // const warranties = Object.entries(this.process.config.warranty.expiration.product_warranty).map(item => item[1])
    // y = this.insertList(doc, x, y,
    //   warranties.map(warranty => warranty.text),
    //   warranties.map(warranty => (this.saleOrder.deliveryDate || dayjs(this.saleOrder.createdAt)).add(warranty.duration.months, 'month').format('DD/MM/YYYY') + '|bold')
    // )
    y = this.separator(doc, y)

    y = this.addParagraph(doc, x, y, this.process.config.warranty.expiration.text)
    y = this.insertNumberedList(doc, x, y, this.process.config.warranty.expiration.list)

    y = this.separator(doc, y)
    return y
  }

  addSignSection (doc: jsPDF, y) {
    const declaration = 'Firma y acepta las condiciones'
    const clientName = this.saleOrder.deal.lead.client.fullName
    const clientUid = this.saleOrder.deal.lead.client.uid

    let height = (y + doc.internal.pageSize.getHeight() - 30) / 2

    doc.setDrawColor('#0033A5')
    doc.line(60, height, doc.internal.pageSize.getWidth() - 60, height)
    height += 5
    doc.text(declaration, this.getCenterWidth(doc, declaration), height)
    height += 5
    doc.setFont(undefined, 'bold')
    doc.text(clientName, this.getCenterWidth(doc, clientName), height)
    height += 5
    doc.text(clientUid, this.getCenterWidth(doc, clientUid), height)
    height += 7
    return height
  }

  async page1 (doc: jsPDF, y, x) {
    const system = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })

    const imageData = await this.loadImage(system.photo)
    const pageWidth = doc.internal.pageSize.getWidth()

    if (imageData) {
      doc.addImage(imageData, 'JPEG', (pageWidth / 2 - 30), 10, 60, 6)
      y += 5
    } else {
      y -= 10
    }

    this.footer(doc)
    await this.addClosingSection(doc, y)
    y = this.separator(doc, y)
    y = this.addTitle(doc, y, 'GARANTÍA DE POSTVENTA')
    y = this.addSubtitle(doc, x, y)
    y = await this.addCarData(doc, x, y)
    y = this.addConditions(doc, x, y)
    y = this.addCoverage(doc, x, y)
    y = this.addConsiderations(doc, x, y)
    y = this.addExclusions(doc, x, y)
    y = this.addExpiracy(doc, x, y)
    this.addSignSection(doc, y)
  }

  addHtmlContent (doc: jsPDF, element: HTMLElement, x: number, y: number) {
    doc.html(element, {
      callback: doc => {
        this.loading = false
        doc.save(`garantia ${this.saleOrder.deal.lead.client.fullName} ${this.saleOrder.deal.lead.client.uid}.pdf`)
      },
      x,
      y, // Ajustar la posición y según la altura de la imagen y el texto
      html2canvas: { scale: 0.5 }, // Ajustar el escalado si es necesario
    })
  }

  get action () {
    return this.options?.action
  }

  get icon () {
    const { action, item } = this

    return action?.icon || item?.icon
  }

  get iconColor () {
    const { options } = this

    return options?.action?.iconColor || 'white'
  }

  get color () {
    const { action, item } = this

    return action?.color || item?.color
  }

  get tooltip () {
    const { options, item } = this

    return options?.tooltip || item?.tooltip || item?.name || item?.contact?.name
  }

  get disabledButton () {
    const { item } = this

    return !item?.id
  }
  }
